import React from "react";
import { useState } from "react";
import HeartButton from "./heartButton";
import HeartRain from "./heartRain";
import Button from '@mui/material/Button';


export default function Page() {
  const [noCount, setNoCount] = useState(0);
  const [yesPressed, setYesPressed] = useState(false);
  const [imageSrc, setImageSrc] = useState("https://gifdb.com/images/high/cute-love-bear-roses-ou7zho5oosxnpo6k.gif");
  const maxButtonSize = 140;
  const yesButtonSize = Math.min(noCount * 20 + 14, maxButtonSize);
  // const images = Array.from({ length: 26 }, (_, index) => `../Images/${String.fromCharCode(65 + index)}.png`);
  const images = Array.from({ length: 26 }, (_, index) => {
    const img = new Image();
    img.src = `../Images/${String.fromCharCode(65 + index)}.png`;
    return img;
  });
  const validPassword = process.env.REACT_APP_PASSWORD;

  const handleNoClick = () => {
    setNoCount(noCount + 1);
    setImageSrc(images[noCount].src);
  };
  
  const getNoButtonText = () => {
    const phrases = [
      "No",
      "Are you sure?",
      "Really sure?",
      "Think again!",
      "Last chance!",
      "Surely not?",
      "You might regret this!",
      "Give it another thought!",
      "Are you absolutely certain?",
      "This could be a mistake!",
      "Have a heart!",
      "Don't be so cold!",
      "Change of heart?",
      "Wouldn't you reconsider?",
      "Is that your final answer?",
      "You're breaking my heart ;(",
    ];

    return phrases[Math.min(noCount, phrases.length - 1)];
  };
  const handlePasswordSubmit = () => {
    if (password.toLowerCase() === validPassword) {
      setCorrectPassword(true);
    } else {
      setPasswordErrorMessage("Incorrect password. Try again.");
    }
  };
  const [password, setPassword] = useState("");
  const [correctPassword, setCorrectPassword] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

  return (
    <div className="flex flex-col items-center justify-center h-screen -mt-16">
      {!correctPassword && 
        <div>
          <h1>Hi Kiki</h1>
          <h1>Will you be my Valentine?</h1>
          <p>Hint: What show did I get you to watch with me on our first date? </p>
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Enter password" />
          <Button variant="contained" color="primary" onClick={handlePasswordSubmit}>
            Submit Password
          </Button>
          <div>{passwordErrorMessage}</div>
        </div>
      }
      {correctPassword && 
        <div> 
          {yesPressed ? (
            <>
            <HeartRain />
            <img src="https://media.tenor.com/gUiu1zyxfzYAAAAi/bear-kiss-bear-kisses.gif" />
            <h2>Ok yay!!! (👍≖‿‿≖)👍</h2>
            <h2>I love youuuuu</h2>
            <img  style={{ width: 'auto', height: '500px' }}  src="../Images/collage.jpg" />
            
            </>
          ) : (
            <>
            <h1>Hi Kiki</h1>
            <h1>Will you be my Valentine?</h1>
            {imageSrc === "https://gifdb.com/images/high/cute-love-bear-roses-ou7zho5oosxnpo6k.gif" ? (
                <img style={{ width: 'auto', height: '300px' }} src={imageSrc} />
              ) : (
                <img style={{ width: 'auto', height: '500px' }} src={imageSrc} />
              )}
              <div>
                <Button variant="contained" color="primary" onClick={handleNoClick}>
                  {noCount === 0 ? "No" : getNoButtonText()}
                </Button>
                <Button variant="contained" color="primary" style={{ fontSize: yesButtonSize }} onClick={() => setYesPressed(true)}>
                  Yes
                </Button>
              </div>
            </>
          )}
        </div>
      }
    </div>
  );
}