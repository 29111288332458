import React, { useEffect, useState } from 'react';

const HeartRain = () => {
  const [hearts, setHearts] = useState<{ top: number; left: number; animationDuration: number; key: number }[]>([]);

  useEffect(() => {
    const interval = setInterval(() => {
      const newHeart = {
        top: -300,
        left: Math.random() * (window.innerWidth - 100),
        animationDuration: Math.random() * 3 + 2,
        key: Date.now(),
      };
      setHearts((prevHearts) => [...prevHearts, newHeart]);
    }, 300);

    const resetTimeout = setTimeout(() => {
      setHearts([]);
    }, 100000);

    return () => {
      clearInterval(interval);
      clearTimeout(resetTimeout);
    }
  }, []);

  return (
    <div style={{ position: 'absolute', top: 0, left: 0, pointerEvents: 'none' }}>
      <style>
      {`
          @keyframes fall {
            from {
              transform: translateY(-100vh);
            }
            to {
              transform: translateY(150vh);
            }
          }
        `}
      </style>
      {hearts.map((heart) => (
        <img
          key={heart.key}
          src="https://www.freeiconspng.com/thumbs/heart-png/heart-png-15.png"
          alt="heart"
          style={{
            position: 'absolute',
            top: heart.top,
            left: heart.left,
            animation: `fall ${heart.animationDuration}s linear`,
            width: '50px',
            height: '50px',
          }}
        />
      ))}
    </div>
  );
};

export default HeartRain;